"use strict";

$(
    function () {
        app.assignNumberControl();
        app.setSelectedLink();
        app.addRegisterCheckBoxes();
        app.attachSvgEvents();
        app.initACBL();
        app.setBackUrls();
        app.addCoverSpan();
        app.saveProductListInfo();
        app.topMenu.init();
        if (!app.isMobile()) {
            $(window).scroll(
                function () {
                    app.floatingHeader();
                }
            );
            app.floatingHeader();
        }
        app.assignProductOver();
        app.initializeScrollButton();
        app.hamburger.init({
            look: 1
        });
        app.color.init();
        app.extraMenu && app.extraMenu.init({
            side: 'right',
            selector: '.extramenu-hamb',
            saveState: false
        });
    }
);

var app = {
    _VERSION: 2,
    _vars: {
        v: '1.1',
        tmp: 25,
        _guid: "16d6da77-34a5-4756-836e-6eed84f873cb",
        putAttributesToProdItemsDirectly: true,
        _customFonts: {
            Linearicons: true
        }
    },
    responsiveMode: true,
    indexLinkPos: 0,
    messages: {},
    productAvailableQuantityUrl: null,
    optionsWereInitialized: false,
    fixTop: 250,
    runOwlOnIndex: function (params) {
        params.oneRow = true;
        params.dots = true;
        app.owlSlider.makeBanner(params);
        app.owlSlider.makeCategoryCarousel({
            margin: 30,
            responsive:{
                0: {
                    items: 1
                },
                400: {
                    items: 2
                },
                800: {
                    items: 3
                },
                1200: {
                    items: 4
                },
                1440: {
                    items: 5
                }
            }
        });
        params.autoHeight = true;
        params.autoplay = true;
        params.autoplayHoverPause = true;
        app.owlSlider.makeProductListSlider(params);
    }
};
